








.dashboard {
  overflow-x: hidden;
  overflow-y: auto;
}
