










































































































































































.static-map {
    padding-left: 15px;
    padding-right: 15px;
}

.home-main {
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;

    .photo {
        border-radius: 25px;
    }

    .buttons {
        border: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 50px;
        padding-left: 50px;
        border-radius: 25px;
    }

    .buttons:hover {
        background-color: #6f0100;
    }

    .button-red {
        background-color: #cb0100;
    }

    .button-white {
        background-color: white;
        color: black;

    }

    .red-text {
        color: #cb0100;
    }

    .red-bar {
        background-color: #cb0100;
        color: white;
        margin-left: -15px;
        margin-right: -15px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .footer {
        background-color: #343a40;
        color: white;

        a {
            color: white;
        }
    }

    ul {
        list-style: none;
    }

    .small-text {
        font-size: 14px;
    }

    .step-image {
        display: block;
        margin: auto;
        margin-bottom: 25px;
    }

    @media (min-width: 992px) {

        .static-map {
            height: 40em;
        }

        .float-middle {
            top: 40%;
            transform: translate(0, -40%);
            position: absolute;
        }

        .top-margin {
            margin-top: 100px;
            margin-bottom: 100px;

        }

        .right-padding {
            margin-top: 100px;
        }

        .step-padding {
            padding: 50px;
            padding-left: 100px;
            padding-right: 100px;
        }

        .red-bar-contents {
            margin: 50px;
        }

        .step-image {
            height: 200px;
        }

        .footer {
            padding: 150px;
        }

    }

    @media (max-width: 992px) {

        .static-map {
            height: 30em;
        }

        .photo {
            margin-top: 25px;
        }

        .top-margin {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .step-padding {
            margin-top: 50px;
            padding-left: 25px;
            padding-right: 25px;
        }

        .red-bar-contents {
            padding-left: 15px;
            padding-right: 15px;

        }

        .step-image {
            height: 150px;
        }

        .footer {
            padding: 25px;
        }

    }
}
