

































































































































































/* .gallery {
  height: 450px;
} */ /* TODO: why is this FIXED HEIGHT? */


.gallery-top {
  height: 60% !important;
  width: 100%;

  .preview {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
}

.gallery-thumbs {
  height: 20% !important;
  box-sizing: border-box;

  .thumbnail {
    height: 100%;
    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      padding-top: 5px;
    }

    &:hover {
      img {
        opacity: 0.5;
      }

      .remove {
        visibility: visible;
        filter: invert(50%);
      }
    }

    .remove {
      color: white;
      cursor: pointer;
      visibility: visible;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .add-image {
    position: relative;
    background-color: #e9e9e9;
    cursor: pointer;
    height: 100%;
    width: 100%;
    // display: inline;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}


.padding {
  padding-bottom: 15px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // opacity: 0;
  z-index: 9999;
  display: flex;
}

.overlay-button-left {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 50%;
  opacity: 0;
  z-index: 9999;
  background-color: black;
}

.overlay-button-right {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: auto;
  height: 100%;
  width: 50%;
  opacity: 0;
  z-index: 9999;
  background-color: black;
}

