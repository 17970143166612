























































.actions {
  min-width: 120px;
}

.buttons {
  margin: 5px;
}

.controls {
  padding: 15px;
}
.padbuttons {
  margin: 5px;
}
