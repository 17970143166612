

































































































































































































































.campaign-controls {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;

  input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
  }

  ::range-track {
    background: #3071a9;
    height: 5px;
    cursor: pointer;
  }

  ::range-thumb {
    height: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #000000;
    box-shadow: 1px 1px 1px #000000;
    background: white;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    margin-top: -5.5px;
    -webkit-appearance: none;
  }
  .play-pause {
    width: 5%;

    .play-icon {
      display: inline-block;
      margin: auto;
    }
  }

  .seek-bar {
    width: 95%;
  }
}

.refresh {
  font-size: 20px;
}

.campaign-preview {
  height: 90%;
  width: 100%;
  overflow: hidden;

  .content {
    height: 100%;
    position: relative;
    margin: auto;

    background: repeating-conic-gradient(#CCC 0% 25%, transparent 0% 50%) 50% / 20px 20px;

    & > * {
      position: absolute;
    }
  }

  &:hover {
    + .campaign-controls {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.5s linear;
    }
  }
}


.durationBar {
  bottom: 0;
  right: 0;

  input {
    width: 4em;
  }
}
