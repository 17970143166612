











































  .fade-enter-active {
    transition: opacity .5s
  }
  .fade-enter {
    opacity: 0
  }

  .display-nav {
    border-bottom: #e9e9e9 solid 1px;
    padding: 0 20px 0 20px;
    position: fixed;
    z-index: 50;
    background-color: white;
  }

  .delete-button {
    margin: 5px;
  }


@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

  @include media-breakpoint-up(xs) {
    .dropdown-toggle {
      display: none;

      &.ellipsis {
        display:block;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .dropdown-toggle {
      display: block;

      &.ellipsis {
        display: none;
      }
    }

    .display-nav {
      position: absolute;
    }
  }

