



























































div.dashboard {
  min-height: 100%;
}

.preview {
  height: 40vh;
}
