










































































































































































































































































































































































@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.name-trim {
  font-size: 100%;
  display: block;
  width: 100%;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-group-item:hover {
  border: 2px dashed red;
}

.ghost {
  opacity: 0.5;
  background: red;
}

.media-item {
  cursor: cell;
  border: 3px solid white;
}

.media-item:hover {
  box-sizing: border-box;
  border: 3px solid lightskyblue;
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.handle {
  float: left;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: grab;
}
