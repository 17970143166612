






























































































































































































































































































































































































































































































































































thumbnail {
  max-width:100%;
}
.cut-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 0.5rem;
}
.item-card:hover {
    background-color: #e6e6e6;
  }
