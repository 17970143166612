



























































































































































































































































































































































































































































































.hour {
  width: 4.16666667%;
  text-align: center;
  border-left: 1px solid black;
  font-size: 0.75rem;

  &.last {
    border-right: 1px solid black;
  }
}

.border-bottom-1 {
  border-bottom: #e0e0e0 1px solid;
}
