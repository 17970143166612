























































.noUi-tooltip {
  display: none;
}
.noUi-active .noUi-tooltip {
  display: block;
}
.noUi-target {
  margin-left: 15px;
  margin-right: 15px;
}
.noUi-pips {
  height: 0px;
}
.noUi-connect {
  background: #007bff;
}
