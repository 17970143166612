
























@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-down(md) {
  .notifications {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 80%
  }
}
.notifications {
  pointer-events: none;
  position: fixed;
  right: 35px;
  bottom: 50px;
  z-index: 1050;
}

.notifications-enter-active, .notifications-leave-active {
  transition: all 0.75s;
}
.notifications-enter, .notifications-leave-to /* .list-leave-active for <2.1.8 */ {
  opacity: 0;
}
