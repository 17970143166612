


































@import 'positions.scss';

.text-item {
  color: white;
}
