
























































































































































































































.status-list {
  list-style: none;
  padding: 0;
  line-height: 1rem;
}

.status-list .fa-icon {
  margin-top: -0.5em;
}

.text-left {
  white-space: pre;
}

.online {
  svg {
    color: green;
    padding-right: 5px;
  }

  span {
    vertical-align: super;
  }
}

.passive {
  svg {
    color: yellow;
    padding-right: 5px;
  }

  span {
    vertical-align: super;
  }
}

.offline {
  svg {
    color: red;
    padding-right: 5px;
  }

  span {
    vertical-align: super;
  }
}
