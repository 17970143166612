






































.fade-enter-active {
  transition: opacity .5s
}
.fade-enter {
  opacity: 0
}

.user-nav {
  border-bottom: #e9e9e9 solid 1px;
  padding: 0 20px 0 20px;
  position: fixed;
  z-index: 100;
  background-color: white;
}

.user-main {
  padding-top: 54px;
}


@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";


@include media-breakpoint-up(md) {
  .user-nav {
    position: absolute;
  }
}


