























.view {
  overflow-y: auto;
}
