































































































































@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.display {
  height: 20vh;
  width: 20vh;
}

.preview {
  height: calc(100% - 1em);
  background: #000;
}
