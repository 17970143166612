



















































































.icon {
  color: black;
}

@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.accordion-menu input[type=checkbox] {
  position: absolute;
  opacity: 0;
}

.accordion-menu label, .accordion-menu a {
  position: relative;
}

/** Hide all submenus */
.accordion-menu .has-children ul li {
  visibility: hidden;
  height: 0;
  transition: visibility 0.3s ease-out 0.3, height ease-out 0.3s;
}

.accordion-menu .has-children ul {
  visibility: hidden;
  height: 0;
  transition: visibility 0s ease-out 0.3s, height ease-out 0.5s;
}

/** Show when input is checked */
.accordion-menu .has-children input[type=checkbox]:checked + label + ul li{
  visibility: visible;
  height: 3.5rem;
  transition: visibility 0s ease-in 0.3s, height ease-in 0.3s;
  display: flex;
  align-items: center;
}

.accordion-menu .has-children input[type=checkbox]:checked + label + ul {
  visibility: visible;
  height: auto;
  transition: visibility 0s ease-in 0.3s, height ease-in 0.3s;
}

.accordion-menu .has-children input[type=checkbox] {
  height: 0;
  width: 0;
}

.sidebar {
  display: block;
  padding: 0 0 0 0;
  z-index: 1020;
  background-color: #f5f5f5;
  border-right: 1px solid #eee;
  box-shadow: 5px 0 15px -7px #aaaaaa;
  left: -83.333333%;
  transition: left 0.3s linear;
  position: fixed;
  overflow-y: auto;
}

.sidebar.visible {
  left: 0;
  transition: left 0.3s linear;
}

.sidebar ul {
  list-style: none;
  padding-left: 0;
}

.sidebar-link {
  cursor: pointer;
  line-height: 3.5rem;
  padding-top: .425rem;
  padding-bottom: .425rem;
  padding-left: 20px;
  display: block;
  margin-bottom: 0;
  border-bottom: solid 1px rgba(189, 195, 199, 0.30);

  &:hover, &.router-link-active {
    background-color: #e6e6e6;
  }
}

.child-link {
  padding-left: 40px;
}

@include media-breakpoint-up(lg) {
  .sidebar {
    left: 0;
    position: relative;
  }
}
