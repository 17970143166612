



























































































































































































.terms-main {
  overflow-y: auto;
  overflow-x: hidden;
}
