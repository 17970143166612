






















































































































































.flex-float-right {
    margin-left: auto;
}

.month {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e6e6e6;
}

.day {
  flex-grow: 1;
  flex-basis: 13.28%;
  border: 1px solid #e6e6e6;
  height: 125px;
  border-left: 0;
  border-right: 0;

  &:hover {
    background-color: #e6e6e6;
  }

  &.padded {
    background-color: #f7f7f7;
  }
}

.day-header {
  height: 1.75rem;
}

.schedule {
  line-height: 1.25rem;
  mix-blend-mode: difference;
  text-align: center;
}
