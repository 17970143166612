




































































































































































































































































































































































































.map-container {
  max-height: 100%;
  height: 100%;
  overflow-y: hidden;
}

.list-view {
  max-height: 295px;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar {
  height: 100%;
  overflow-y: auto;
}
