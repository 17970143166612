





































































































































































































.remove-button {
  margin-right: 0.2em;
}

.status-list {
  list-style: none;
  padding: 0;
  line-height: 1rem;
}

.online {
  svg {
    color: green;
    padding-right: 5px;
  }

  span {
    vertical-align: super;
  }
}

.passive {
  svg {
    color: yellow;
    padding-right: 5px;
  }

  span {
    vertical-align: super;
  }
}

.offline {
  svg {
    color: red;
    padding-right: 5px;
  }

  span {
    vertical-align: super;
  }
}
