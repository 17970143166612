



































.text-item {
  // height: 100%;
  // width: 100%;
  background-color: black;
  color: white;
  margin: 0 auto;
}
.text-center {
  background: repeating-conic-gradient(#CCC 0% 25%, transparent 0% 50%) 50% / 20px 20px;
}
