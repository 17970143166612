


























































































































































































































































































































































@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(lg) {
  .dropdown-toggle {
    display: inline-block;
  }

  .link-toggle {
    display: inline-block;
  }

  .logo {
    display: inline-block;
  }
}

@include media-breakpoint-down(md) {
  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-up(xs) {
  .toggle-center {
    align-self: center;
    cursor: pointer;
  }

  .fade-enter-active {
    transition: opacity 0.5s;
  }

  .fade-enter {
    opacity: 0;
  }

  .content {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .content.faded {
    background: rgba(0, 0, 0, 0.7);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.navigation-box {
  background-color: white;
  text-align: left;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-y: scroll;
  max-height: 30em;
}

.navigation-box-item {
  color: black;
  &:hover {
    color: lighten(black, 20%);
    text-decoration: none;
  }
}

.icon {
  color: white;
}

.nav-link {
  &.inline {
    display: inline;
  }
}

a:focus {
  color: darken(white, 20%);
}

.flatpickr-calendar {
  z-index: 1080 !important;
}
