





















































































































































































































































































































































































































.header {
  width: 100%;
}

.pointer {
  color: black !important;
  fill: black !important;
  cursor: pointer;
}

.checkout {
  align-content: flex-start;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  .bookings {
    padding-top: 15px;
  }

  .submit {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
