.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.left {
    text-align: left;
    left: 0;
}

.right {
    text-align: right;
    right: 0;
}

.center {
    text-align: center;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0%);
}
