




















































.wvh-50 {
  width: 50vw;
}

  .campaign-nav {
    z-index: 1001;
  }

  .campaign-main {
    z-index: 1000;
  }

  @import "node_modules/bootstrap/scss/_functions.scss";
  @import "node_modules/bootstrap/scss/_variables.scss";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

    .campaign-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
