




























































.image {
  position: absolute;
}

@import 'positions.scss';
