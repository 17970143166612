



































































































































































































































































































@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.dash-text {
  font-family: monospace;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.live-text {
  border: 1px dashed #000;
}

.prev {
  background: #000;
  height: 33.33vh;
  margin-bottom: 1em;
  padding-bottom: 1.5em;
}

.progress-label {
  font-size: 125%;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
