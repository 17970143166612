































































































.video {
  position: absolute;
}

@import 'positions.scss';
