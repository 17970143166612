





















































.leave-button {
  margin: 5px;
}
